import BaseController from '../baseSystemController';

export default class BaseTournamentController extends BaseController {
  constructor({ type, ...props }) {
    super({ type, ...props });
    this._data = null;
  }

  updateData(data) {
    const nextData = this._extractRelevantData(data);
    if (this._data === nextData) return;

    this._data = nextData;
    this._onDataUpdated();
  }

  beforeRemove() {
    super.beforeRemove();
    this._data = null;
  }

  _extractRelevantData(data) {
    const selector = this.dataSelector;
    if (!selector) return;

    return selector(data);
  }

  _onDataUpdated() {}

  get dataSelector() {}

  get elementsIdPrefix() {
    return 'system_ui__tournaments'
  }

  get data() {
    return this._data;
  }
}
