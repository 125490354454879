import BasePlayerUi from '../player/basePlayerUI';

export default class PortraitUi extends BasePlayerUi {
  constructor({ config, partnerConfig }) {
    super({ config, partnerConfig });

    this._config = {
      ...this._config,
      offsets: {
        top: 0,
        left: 0,

        tournaments_panel: {
          top: 0,
          left: 0,
        }
      },
    };

    if (config) {
      this._config.offsets = { ...this._config.offsets, ...config.offsets };
    }

    if (this._config.init_controls) {
      this._excludedControllerTypes.add(this.controllerTypes.ECT_MAX_BET);
      this._excludedControllerTypes.add(this.controllerTypes.ECT_FULL_SCREEN);
    }

    this.init();
  }

  _getAutoplayButtonMarkup() {
    return this._excludedControllerTypes.has(this.controllerTypes.ECT_AUTO_SPIN) ?
      `<div id=${this.controllersContainersIds[this.controllerTypes.ECT_INFO]}></div>` :
      `<div id=${this.controllersContainersIds[this.controllerTypes.ECT_AUTO_SPIN]}></div>`
  }

  _getInfoButtonMarkup() {
    return this._excludedControllerTypes.has(this.controllerTypes.ECT_AUTO_SPIN) ?
      `<div></div>` :
      `<div class="ui-container__panel">
          <div id=${this.controllersContainersIds[this.controllerTypes.ECT_INFO]}>
          </div>
       </div>`
  }

  _getConstantMarkup() {
    return `<div class="ui-container__tournaments_label_container" data-orientation="portrait" style="top: ${this._config.offsets.tournaments_panel.top}px; left: ${this._config.offsets.tournaments_panel.left}px">
      <div id=${this.controllersContainersIds[this.controllerTypes.ECT_TOURNAMENTS_LABEL]}>
      </div>
    </div>`
  }

  _getMarkup() {
    return `
        <!--            modals-->
    <div class="ui-container__bet_selector_container" data-orientation="portrait" style="padding-bottom: ${-this._config.offsets.top}px">
      <div id=${this.controllersContainersIds[this.controllerTypes.ECT_BET_SELECTOR_TABLE]}>
      </div>
    </div>
    
    ${this._getConstantMarkup()}

    <div class="ui-container__portrait">
      <div class="ui-container__portrait_container" style="top: ${this._config.offsets.top}px; left: ${this._config.offsets.left}px">
        <!--            balance-->
        <div class="ui-container__panel">
          <div id=${this.controllersContainersIds[this.controllerTypes.ECT_BALANCE]}>
          </div>
        </div>
        <!--            win-->
        <div class="ui-container__panel">
          <div id=${this.controllersContainersIds[this.controllerTypes.ECT_WIN]}>
          </div>
        </div>
        <!--            bet-->
        <div class="ui-container__panel">
          <div id=${this.controllersContainersIds[this.controllerTypes.ECT_BET]}>
          </div>
        </div>
        <!--          menu-->
        <div class="ui-container__panel">
          <div id=${this.controllersContainersIds[this.controllerTypes.ECT_MENU]}>
          </div>
        </div>
        <!--          sound/spin/autoplay-->
        <div class="ui-container__panel ui-container__portrait_container__spin_panel">
          <div class="ui-container__panel">
            <div id=${this.controllersContainersIds[this.controllerTypes.ECT_SOUND]}>
            </div>
          </div>
      
          <div id=${this.controllersContainersIds[this.controllerTypes.ECT_SPIN]}>
          </div>
      
          <div class="ui-container__panel">
            ${this._getAutoplayButtonMarkup()}
          </div>
        </div>
        <!--          bets-->
        <div class="ui-container__panel">
          <div id=${this.controllersContainersIds[this.controllerTypes.ECT_BET_SELECT]}>
          </div>
        </div>
        <!--          info-->
        ${this._getInfoButtonMarkup()}
        
        <div></div>
        
        <div class="ui-container__portrait_container__total_win_panel">
          <div id=${this.controllersContainersIds[this.controllerTypes.ECT_TOTAL_WIN]}>
          </div>
        </div>
      </div>
    </div>`;
  }
}
