import ControllerBonusDetails from '../tournaments/ControllerBonusDetails';
import eControllerTypes from './eControllerTypes';

export default class ControllerStartScreenBonusDetails extends ControllerBonusDetails {

  get controllerType() {
    this._type = eControllerTypes.ETSCT_BONUS_DETAILS;
    return super.controllerType;
  }

  get elementsIdPrefix() {
    return 'tournament_start_screen';
  }
}
