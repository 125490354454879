import BaseTabView from './baseTabView';
import { OP_WRAPPER_LOCALIZATION_PREFIX } from '../../../../../utils/constants';
import eTabViewTypes from './eTabViewsTypes';

export default class ControllerLeaderboardView extends BaseTabView {
  constructor() {
    super({ type: eTabViewTypes.ETWT_LEADERBOARD });
    this._eButtonsTypes = {
      EBT_LOAD_MORE: 'load_more',
    };
    this._eEventTypes = {
      LOAD_MORE_CLICK: this.getEventName(this._eButtonsTypes.EBT_LOAD_MORE),
    };
    this._eButtonsActions = {
      [this._eButtonsTypes.EBT_LOAD_MORE]: this._onLoadMoreClick.bind(this),
    };
    this._eElementsTypes = {
      EET_BUTTON_LOAD_MORE: this._eButtonsTypes.EBT_LOAD_MORE,
      EET_BUTTON_LOAD_MORE_LABEL: `${this._eButtonsTypes.EBT_LOAD_MORE}_label`,
      EET_MAIN_TABLE_BODY: 'main_table_body',
      EET_SUB_TABLE_BODY: 'sub_table_body',
      EET_TABLE_HEAD_1: 'table_head_1',
      EET_TABLE_HEAD_2: 'table_head_2',
      EET_TABLE_HEAD_3: 'table_head_3',
      EET_TABLE_HEAD_4: 'table_head_4',
    };
    this._localizations = {
      [this._eElementsTypes.EET_TABLE_HEAD_1]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.tournaments.leaderboard.head_1`,
      [this._eElementsTypes.EET_TABLE_HEAD_2]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.tournaments.leaderboard.head_2`,
      [this._eElementsTypes.EET_TABLE_HEAD_3]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.tournaments.leaderboard.head_3`,
      [this._eElementsTypes.EET_TABLE_HEAD_4]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.tournaments.leaderboard.head_4`,
      [this._eElementsTypes.EET_BUTTON_LOAD_MORE_LABEL]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.tournaments.leaderboard.button_load_more`,
    }

    this._rows = [];
    this._subRows = [];
  }

  _afterInit() {
    super._afterInit();
    this._placeholderRow = this.interactiveElements[this._eElementsTypes.EET_MAIN_TABLE_BODY].querySelector('tr.placeholder');
  }

  _onDataUpdated() {
    super._onDataUpdated();
    if (!this._data || !this._data.leaderboard) return console.error('No data for leaderboard');

    const { list, neighbors, totalCount } = this._data.leaderboard;
    this._updateMainTable(list, totalCount, this._data.userId);
    this._updateSubTable(neighbors, this._data.userId);
  }

  _updateMainTable(list, totalCount, userId) {
    const mainTableBody = this.interactiveElements[this._eElementsTypes.EET_MAIN_TABLE_BODY];
    const newRows = [];

    if (list.length > this._rows.length) {
      mainTableBody.removeChild(this._placeholderRow);
    } else if (list.length < this._rows.length) {
      const rowsToRemove = this._rows.splice(list.length);
      rowsToRemove.forEach(row => row.remove());
    }

    for (let i = 0; i < list.length; i++) {
      const item = list[i];
      const isPlayer = item.userId === userId;
      const row = this._rows[i];

      if (row) {
        this._updateRow(row, item, isPlayer);
      } else {
        const newRow = this._createRow(item, isPlayer);
        this._rows.push(newRow);
        newRows.push(newRow);
      }
    }

    if (newRows.length) {
      mainTableBody.append(...newRows);
      mainTableBody.appendChild(this._placeholderRow);
    }

    if (list.length < totalCount) {
      mainTableBody.setAttribute('data-has-more-items', '');
    } else {
      mainTableBody.removeAttribute('data-has-more-items');
    }
  }

  _updateSubTable(list, userId) {
    const subTableBody = this.interactiveElements[this._eElementsTypes.EET_SUB_TABLE_BODY];

    if (list.length < this._subRows.length) {
      const rowsToRemove = this._subRows.splice(list.length);
      rowsToRemove.forEach(row => row.remove());
    }

    for (let i = 0; i < list.length; i++) {
      const item = list[i];
      const isPlayer = item.userId === userId;
      const row = this._subRows[i];

      if (row) {
        this._updateRow(row, item, isPlayer);
      } else {
        const newRow = this._createRow(item, isPlayer);
        this._subRows.push(newRow);
        subTableBody.appendChild(newRow);
      }
    }
  }

  async _onLoadMoreClick() {
    console.log('SERVER REQUEST');
    this.block(this._eButtonsTypes.EBT_LOAD_MORE);
    const button = this.interactiveElements[this._eElementsTypes.EET_BUTTON_LOAD_MORE];
    button.setAttribute('data-loading', '');

    await window.OPWrapperService.serverManager.requestTournamentsLeaderboard(this._data.leaderboard.list.length, this._data.tournamentId);

    this.unblock(this._eButtonsTypes.EBT_LOAD_MORE);
    button.removeAttribute('data-loading');
  }

  beforeRemove() {
    super.beforeRemove();

    this._rows = [];
    this._subRows = [];
  }

  _createRow(data, isPlayer) {
    const { place, userId, score, prize } = data;
    const row = document.createElement('tr');
    row.classList.add('system-ui-container__tournaments__stat_row');
    if (isPlayer) row.classList.add('player');

    row.innerHTML = `<td class="system-ui-container__tournaments__stat_cell">
                      <span title=${place}>${place}</span>
                    </td>
                    <td class="system-ui-container__tournaments__stat_cell">
                      <span title=${userId}>${userId}</span>
                    </td>
                    <td class="system-ui-container__tournaments__stat_cell">
                      <span title=${score}>${score}</span>
                    </td>
                    <td class="system-ui-container__tournaments__stat_cell">
                      <span title=${prize}>${prize} EUR</span>
                    </td>`;

    return row;
  }

  _updateRow(row, data, isPlayer) {
    const [placeSpan, userIdSpan, scoreSpan, prizeSpan] = row.querySelectorAll('span');
    const { place, userId, score, prize } = data;

    placeSpan.innerText = place;
    userIdSpan.innerText = userId;
    scoreSpan.innerText = score;
    prizeSpan.innerText = `${prize} EUR`;

    if (isPlayer) {
      row.classList.add('player');
    } else {
      row.classList.remove('player');
    }
  }

  get dataSelector() {
    return (data) => ({ leaderboard: data.data.leaderboard, tournamentId: data.tournamentId, userId: data.userId });
  }

  _getMarkup() {
    return `<table class="tournament_leaderboard">
                  <thead>
                  <tr>
                    <th>
                      <span id=${this.interactiveElementsIds[this._eElementsTypes.EET_TABLE_HEAD_1]}>${this._getLocalization(this._localizations[this._eElementsTypes.EET_TABLE_HEAD_1])}</span>
                    </th>
                    <th>
                      <span id=${this.interactiveElementsIds[this._eElementsTypes.EET_TABLE_HEAD_2]}>${this._getLocalization(this._localizations[this._eElementsTypes.EET_TABLE_HEAD_2])}</span>
                    </th>
                    <th>
                      <span id=${this.interactiveElementsIds[this._eElementsTypes.EET_TABLE_HEAD_3]}>${this._getLocalization(this._localizations[this._eElementsTypes.EET_TABLE_HEAD_3])}</span>
                    </th>
                    <th>
                      <span id=${this.interactiveElementsIds[this._eElementsTypes.EET_TABLE_HEAD_4]}>${this._getLocalization(this._localizations[this._eElementsTypes.EET_TABLE_HEAD_4])}</span>
                    </th>
                  </tr>
                  </thead>
                  <tbody id=${this.interactiveElementsIds[this._eElementsTypes.EET_MAIN_TABLE_BODY]} class="system-ui-container__tournaments__stat_list" data-has-more-items>
                  <tr class="placeholder">
                    <td>
                      <span>•••</span>
                    </td>
                    <td>
                      <button id=${this.interactiveElementsIds[this._eElementsTypes.EET_BUTTON_LOAD_MORE]} class="secondary">
                        <span id=${this.interactiveElementsIds[this._eElementsTypes.EET_BUTTON_LOAD_MORE_LABEL]}>${this._getLocalization(this._localizations[this._eElementsTypes.EET_BUTTON_LOAD_MORE_LABEL])}</span>
                        <div class="system-ui-container__tournaments__spinner small">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      </button>
                    </td>
                  </tr>
                  </tbody>

                  <tbody id=${this.interactiveElementsIds[this._eElementsTypes.EET_SUB_TABLE_BODY]} class="system-ui-container__tournaments__stat_list">
                  </tbody>
                </table>`
  }
}
