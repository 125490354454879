import BaseTabView from './baseTabView';
import eTabViewTypes from './eTabViewsTypes';
import { OP_WRAPPER_LOCALIZATION_PREFIX } from '../../../../../utils/constants';

export default class ControllerPrizesView extends BaseTabView {
  constructor() {
    super({ type: eTabViewTypes.ETWT_PRIZES });
    this._eElementsTypes = {
      EET_TABLE_BODY: 'table_body',
      EET_TABLE_HEAD_1: 'table_head_1',
      EET_TABLE_HEAD_2: 'table_head_2',
    };
    this._localizations = {
      [this._eElementsTypes.EET_TABLE_HEAD_1]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.tournaments.prizes.head_1`,
      [this._eElementsTypes.EET_TABLE_HEAD_2]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.tournaments.prizes.head_2`,
    }

    this._rows = [];
  }

  init(container) {
    super.init(container);
  }

  _onDataUpdated() {
    super._onDataUpdated();
    if (!this._data) return console.error('No data for prizes');
    this._updateTable(this._data);
  }

  _updateTable(list) {
    const container = this.interactiveElements[this._eElementsTypes.EET_TABLE_BODY];
    const newRows = [];

    if (list.length < this._rows.length) {
      const rowsToRemove = this._rows.splice(list.length);
      rowsToRemove.forEach(row => row.remove());
    }

    for (let i = 0; i < list.length; i++) {
      const item = list[i];
      const row = this._rows[i];

      if (row) {
        this._updateRow(row, item);
      } else {
        const newRow = this._createRow(item);
        this._rows.push(newRow);
        newRows.push(newRow);
      }
    }

    if (newRows.length) {
      container.append(...newRows);
    }
  }

  _createRow({ range, reward }) {
    const row = document.createElement('tr');
    row.classList.add('system-ui-container__tournaments__stat_row');

    row.innerHTML = `<td class="system-ui-container__tournaments__stat_cell">
                       <span>${range}</span>
                     </td>
                     <td class="system-ui-container__tournaments__stat_cell">
                       <span>${reward} EUR</span>
                     </td>`;

    return row;
  }

  _updateRow(row, data) {
    const [rangeSpan, rewardSpan] = row.querySelectorAll('span');
    const { range, reward } = data;

    rangeSpan.innerText = range;
    rewardSpan.innerText = `${reward} EUR`;
  }

  beforeRemove() {
    super.beforeRemove();
    this._rows = [];
  }

  get dataSelector() {
    return (data) => data.data.prizes;
  }

  _getMarkup() {
    return `<table class="tournament_prizes">
              <thead>
                <tr>
                 <th id=${this.interactiveElementsIds[this._eElementsTypes.EET_TABLE_HEAD_1]}>Position</th>
                 <th id=${this.interactiveElementsIds[this._eElementsTypes.EET_TABLE_HEAD_2]}>Prize</th>
                </tr>
              </thead>
              <tbody id=${this.interactiveElementsIds[this._eElementsTypes.EET_TABLE_BODY]} class="system-ui-container__tournaments__stat_list">
              </tbody>
            </table>`
  }
}
