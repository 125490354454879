import GameModel from '../models/GameModel';
import { getUrlParams } from '../utils/url';

const URL = '/game_request';
const urlParams = getUrlParams(window.location.search);
const devEnvironments = [/localhost/, /0\.0\.0\.0/, /192\.168\./];
if (devEnvironments.some(regexp => regexp.test(window.location.origin))) {
  if (!Object.keys(urlParams).length) {
    getSession();
  }
}


export const requestConfig = async () => {
  const responseInit = await request('get', '/game_init');
  if (!responseInit) return;

  await window.OPWrapperService.init({
    partnerConfig: responseInit.partner_config,
    language: responseInit.lang,
    currencyInfo: {
      currency: responseInit.currency,
      decimals: responseInit.decimals,
    },
  });

  const params = {
    id: 'config',
  };

  const response = await request('post', URL, params).catch(console.error);
  if (!response || !response.success) return;
  window.OPWrapperService.freeBetsController.setData(response.freebets);

  GameModel.currency = response.currency;
  //todo: remove decimal in next release
  if (response.decimal) {
    console.warn('decimal field will deprecated soon in config request');
  }
  GameModel.decimal = response.decimal || response.decimals;
  GameModel.balance = response.balance;
  GameModel.bets = response.bets_available;
  GameModel.availableBets = response.bets_available;
  GameModel.currentBetIndex = response.hasOwnProperty('bet_index') ? response.bet_index : 0;

  if (response.freebets) {
    const freeBetsIsActive = response.freebets.status === window.OPWrapperService.freeBetsController.eFreeBetsStatusType.EFBST_ACTIVE;

    if (freeBetsIsActive) {
      GameModel.bets = response.freebets.bet_levels;
      GameModel.currentBetIndex = response.freebets.bet_level;
    }
  }
};

export const requestNewGame = async () => {
  const params = {
    id: 'new_game',
    betIndex: GameModel.currentBetIndex,
  };

  let response = await request('post', URL, params)
    .catch(console.error);

  window.OPWrapperService.freeBetsController.setData(response.freebets);
  window.OPWrapperService.freeBetsController.show();
  response.is_win = response.is_win === 1;

  GameModel.bets = GameModel.availableBets;

  let freeBetsIsActive;
  let isLastFreeBet;

  if (response.freebets) {
    freeBetsIsActive = response.freebets.status === window.OPWrapperService.freeBetsController.eFreeBetsStatusType.EFBST_ACTIVE;
    isLastFreeBet = response.freebets.status === window.OPWrapperService.freeBetsController.eFreeBetsStatusType.EFBST_PLAYED;

    if (freeBetsIsActive) {
      GameModel.bets = response.freebets.bet_levels;
      GameModel.currentBetIndex = response.freebets.bet_level;
    } else if (isLastFreeBet) {
      GameModel.currentBetIndex = 0;
    }
  }

  if (freeBetsIsActive || isLastFreeBet) {
    window.OPWrapperService.ControllerStatistic.increaseFreeBetsCount();
  } else {
    window.OPWrapperService.ControllerStatistic.increaseSpinCount();
  }

  return response;
};

async function getSession() {
  try {
    const raw = await fetch('https://int.dev.onlyplay.net/integration/request_frame/?game=headsortails&no_redirect', {
      method: 'GET',
    });
    const response = await raw.json();
    if (response.url) {
      const searchParams = response.url.split('?')[1];
      window.location = `${window.location.origin}?${searchParams}`;
    }
  } catch (e) {
    console.error(e);
  }
}

let waitingForResponse = false;
const request = async (method, url, params) => {
  if (waitingForResponse) {
    throw new Error('Already waiting for response.')
  }

  if (!navigator.onLine) {
    window.OPWrapperService.showError(window.OPWrapperService.errors.NO_CONNECTION.CODE);
    throw new Error('No Internet connection.')
  }

  waitingForResponse = true;

  url = `https://${urlParams.api + url}/?sid=${urlParams.sid}&gid=${urlParams.gid}`;

  if (params) {
    params.ver = '1.1';
  }

  if (params && method !== 'post')
    url += `&req=${JSON.stringify(params)}`;

  const options = { method };
  if (method === 'post')
    options.body = JSON.stringify(params);


  let response = await fetch(url, options);
  waitingForResponse = false;

  if (response.status === 500) {
    throw new Error('Server Down');
  }

  response = await response.json()
    .catch((error) => {
      console.error(error);
      console.error('Response', response);
      throw error;
    });

  if (!response.success) {
    //9001 - insufficient balance
    window.OPWrapperService.showError(response.code, response.message);
    throw response;
  }
  return response;
};
