import BaseTournamentController from './tournaments/baseTournamentController';
import eControllerTypes from './eControllerTypes';
import { OP_WRAPPER_LOCALIZATION_PREFIX } from '../../../utils/constants';

export default class ControllerModalTournamentDetails extends BaseTournamentController {
  constructor({ container, ...props }) {
    super({ type: eControllerTypes.ECT_MODAL_TOURNAMENT_DETAILS, ...props });
    this._defaultSizes = {
      H: {
        width: 1920,
        height: 1080,
      },
      V: {
        width: 918,
        height: 1920
      }
    };
    this._eButtonsTypes = {
      EBT_CLOSE: 'close_button',
    };
    this._eButtonsActions= {
      [this._eButtonsTypes.EBT_CLOSE]: () => this.hide(),
    };
    this._eEventTypes = {
      CLOSE_CLICK: this.getEventName(this._eButtonsTypes.EBT_CLOSE),
    };
    this._eElementsTypes = {
      EET_SCALE_ROOT: 'scale_root',
      EET_HEADER_TITLE: 'header_title',
      EET_CLOSE_BUTTON: this._eButtonsTypes.EBT_CLOSE,
      EET_TITLE: 'title',
      EET_DESCRIPTION: 'description',
    };
    this._localizations = {
      [this._eElementsTypes.EET_HEADER_TITLE]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.tournaments.details.modal_header_title`,
      [this._eElementsTypes.EET_TITLE]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.tournaments.details.modal_title`,
      [this._eElementsTypes.EET_DESCRIPTION]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.tournaments.details.modal_description`,
    };

    this.init(container);
    this.hide();
  }

  _afterInit() {
    super._afterInit();
    this.scaleRoot = this.interactiveElements[this._eElementsTypes.EET_SCALE_ROOT];
  }

  set scaleData({ scaleData, rootScale }) {
    this._scaleData = scaleData;
    const orientation = this._scaleData.innerWidth <= this._scaleData.innerHeight ? 'V' : 'H';
    const uiScale = Math.min(this._scaleData.innerWidth / this._defaultSizes[orientation].width, this._scaleData.innerHeight / this._defaultSizes[orientation].height);

    Object.assign(this.scaleRoot.style, {
      transform: `scale(${uiScale}) translateZ(0)`,
    });
  }

  _getMarkup() {
    return `<div class="system-ui-container__modal_wrapper">
              <div id=${this.interactiveElementsIds[this._eElementsTypes.EET_SCALE_ROOT]} class="system-ui-container__modal_container system-ui-container__tournaments__modal__rules">
                <div class="system-ui-container__tournaments__modal__rules__header">
                  <svg width="130" height="92" viewBox="0 0 130 92" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M130 92L64.375 26.125L51.875 60.625L13.375 75.125L30.25 92H81.5H130Z" fill="#ED1132" />
                    <path
                        d="M65.8758 31.625C65.8758 30.1093 65.8003 28.6116 65.6571 27.1335L39.1417 0.657657C38.7491 0.252641 38.2003 0 37.5951 0H2.15643C0.970493 0 0.00018096 0.970313 0.00018096 2.15625V6.36184C0.00018096 6.94295 0.233235 7.47213 0.610399 7.86097L4.2913 11.5156C4.2913 13.0255 4.2913 14.6488 4.2913 16.1893C4.2913 19.7592 5.3457 21.7774 7.46638 23.8546L15.005 31.3937C15.0814 32.46 14.6302 33.5514 13.6492 34.3205L10.525 36.7702C6.35824 40.1057 4.29112 42.0623 4.29112 47.0607C4.29112 49.7048 4.29112 52.5951 4.29112 54.7317H2.15625C0.970313 54.7317 0 55.702 0 56.888V61.0938C0 61.7561 0.302775 62.351 0.77661 62.7474L15.5194 77.4184C16.9539 77.5531 18.4063 77.625 19.8758 77.625C45.2809 77.625 65.8758 57.0301 65.8758 31.625Z"
                        fill="#ED1132" />
                    <path
                        d="M26.1012 28.9294L29.2255 26.4797C33.3922 23.1442 35.4594 21.1876 35.4594 16.1892C35.4594 11.191 35.4594 5.31055 35.4594 5.31055H4.29004C4.29004 5.31055 4.29004 11.1908 4.29004 16.1892C4.29004 21.1874 6.35716 23.144 10.5239 26.4797L13.6482 28.9294C15.468 30.3565 15.4682 32.8935 13.6482 34.3206L10.5239 36.7702C6.35716 40.1058 4.29004 42.0624 4.29004 47.0608C4.29004 52.0589 4.29004 57.9394 4.29004 57.9394H35.4592C35.4592 57.9394 35.4592 52.0591 35.4592 47.0608C35.4592 42.0626 33.392 40.106 29.2253 36.7702L26.101 34.3206C24.2812 32.8935 24.2814 30.3563 26.1012 28.9294Z"
                        fill="white" />
                    <path
                        d="M37.5949 8.51809H2.15625C0.970313 8.51809 0 7.54777 0 6.36184V2.15625C0 0.970313 0.970313 0 2.15625 0H37.5949C38.7809 0 39.7512 0.970313 39.7512 2.15625V6.36184C39.7512 7.54777 38.7809 8.51809 37.5949 8.51809Z"
                        fill="#56CCF2" />
                    <path
                        d="M37.5949 63.2495H2.15625C0.970313 63.2495 0 62.2792 0 61.0933V56.8877C0 55.7018 0.970313 54.7314 2.15625 54.7314H37.5949C38.7809 54.7314 39.7512 55.7018 39.7512 56.8877V61.0933C39.7512 62.2792 38.7809 63.2495 37.5949 63.2495Z"
                        fill="#56CCF2" />
                    <path
                        d="M7.20752 19.6758C7.20752 19.6758 7.95628 22.1614 12.4783 25.5156C15.1093 27.467 17.753 29.4006 19.8748 31.6245C19.8748 31.6245 24.641 27.467 27.272 25.5156C31.7942 22.1614 32.5427 19.6758 32.5427 19.6758H7.20752Z"
                        fill="#F5C827" />
                    <path
                        d="M29.5337 43.125C28.5242 43.125 11.2273 43.125 10.2178 43.125C7.83693 43.125 6.92358 45.4609 6.92358 47.198C6.92358 48.935 6.92358 52.2891 6.92358 52.2891H32.8281C32.8281 52.2891 32.8281 48.9348 32.8281 47.198C32.8279 45.4609 31.9145 43.125 29.5337 43.125Z"
                        fill="#F5C827" />
                  </svg>
                  <div>
                    <span id=${this.interactiveElementsIds[this._eElementsTypes.EET_HEADER_TITLE]}>${this._getLocalization(this._localizations[this._eElementsTypes.EET_HEADER_TITLE])}</span>
                  </div>
            
                  <button id=${this.interactiveElementsIds[this._eElementsTypes.EET_CLOSE_BUTTON]} class="system-ui-container__close_button">
                    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M20.9989 51.3331H34.9989C46.6655 51.3331 51.3322 46.6664 51.3322 34.9997V20.9997C51.3322 9.33305 46.6655 4.66638 34.9989 4.66638H20.9989C9.33219 4.66638 4.66553 9.33305 4.66553 20.9997V34.9997C4.66553 46.6664 9.33219 51.3331 20.9989 51.3331Z"
                          fill="#17142A" stroke="#FFFFFF" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M21.3965 34.6032L34.6032 21.3965" stroke="white" stroke-width="2.5" stroke-linecap="round"
                            stroke-linejoin="round" />
                      <path d="M34.6032 34.6032L21.3965 21.3965" stroke="white" stroke-width="2.5" stroke-linecap="round"
                            stroke-linejoin="round" />
                    </svg>
                  </button>
                </div>
            
                <div class="system-ui-container__tournaments__modal__rules__body">
                  <div class="system-ui-container__tournaments__modal__rules__body_content">
                    <h1 id=${this.interactiveElementsIds[this._eElementsTypes.EET_TITLE]}>${this._getLocalization(this._localizations[this._eElementsTypes.EET_TITLE])}</h1>
                    <p id=${this.interactiveElementsIds[this._eElementsTypes.EET_DESCRIPTION]}>${this._getLocalization(this._localizations[this._eElementsTypes.EET_DESCRIPTION])}</p>
                  </div>
                </div>
              </div>
            </div>`
  }
}
