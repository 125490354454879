import BaseUi from '../baseUi';
import PlayerUIInterface from './playerUIInterface';
import eControllerTypes from '../../controllers/player/eControllerTypes';
import {
  ControllerAutoPlay, ControllerBalance, ControllerBet, ControllerBetSelect, ControllerBetSelectorTable,
  ControllerFullScreen, ControllerInfo, ControllerMaxBet, ControllerMenu, ControllerSound, ControllerSpin,
  ControllerTotalWin, ControllerTournamentsLabel, ControllerWin
} from '../../controllers';

export default class BasePlayerUi extends BaseUi {
  constructor(props) {
    super(props);

    this._config = {
      init_controls: true,
      ...this._config,
    };

    this._eControllersClasses = {
      [this.controllerTypes.ECT_BALANCE]: ControllerBalance,
      [this.controllerTypes.ECT_BET]: ControllerBet,
      [this.controllerTypes.ECT_WIN]: ControllerWin,
      [this.controllerTypes.ECT_INFO]: ControllerInfo,
      [this.controllerTypes.ECT_MENU]: ControllerMenu,
      [this.controllerTypes.ECT_FULL_SCREEN]: ControllerFullScreen,
      [this.controllerTypes.ECT_TOTAL_WIN]: ControllerTotalWin,
      [this.controllerTypes.ECT_SPIN]: ControllerSpin,
      [this.controllerTypes.ECT_AUTO_SPIN]: ControllerAutoPlay,
      [this.controllerTypes.ECT_SOUND]: ControllerSound,
      [this.controllerTypes.ECT_MAX_BET]: ControllerMaxBet,
      [this.controllerTypes.ECT_BET_SELECT]: ControllerBetSelect,
      [this.controllerTypes.ECT_BET_SELECTOR_TABLE]: ControllerBetSelectorTable,
      [this.controllerTypes.ECT_BET_SELECTOR_TABLE]: ControllerBetSelectorTable,
      [this.controllerTypes.ECT_TOURNAMENTS_LABEL]: ControllerTournamentsLabel,
    };
    this._constantControllerTypes = new Set([this.controllerTypes.ECT_TOURNAMENTS_LABEL]);
  }

  _checkConfig() {
    if (!this._config.init_controls) {

      const types = Object.values(this.controllerTypes);
      for (const type of types) {
        if (!this._constantControllerTypes.has(type)) this._excludedControllerTypes.add(type)
      }

      this._getMarkup = this._getConstantMarkup;
      return;
    }

    if (this._partnerConfig.disableAutoplay) {
      this._excludedControllerTypes.add(this.controllerTypes.ECT_AUTO_SPIN)
    }
  }

  _addExtraControllersListeners() {
    const betsSelectController = this.controllers[this.controllerTypes.ECT_BET_SELECT];
    const betsTableController = this.controllers[this.controllerTypes.ECT_BET_SELECTOR_TABLE];
    const tournamentsLabelController = this.controllers[this.controllerTypes.ECT_TOURNAMENTS_LABEL];
    // const honestyController = this.controllers[this.controllerTypes.ECT_HONESTY];
    const events = this.events;

    if (betsSelectController) {
      betsSelectController.on(events[this.controllerTypes.ECT_BET_SELECT].SHOW_BETS_CLICK, () => {
        if (betsTableController) betsTableController.show();
      });

      betsSelectController.on(events[this.controllerTypes.ECT_BET_SELECT].HIDE_BETS_CLICK, () => {
        if (betsTableController) betsTableController.hide();
      });
    }
  }

  _afterControllersInit() {
    this._addExtraControllersListeners();

    if (!window.OPWrapperService.model.data.tournamentsInfo || !window.OPWrapperService.model.data.tournamentsInfo.active.length) {
      this.controllers[this.controllerTypes.ECT_TOURNAMENTS_LABEL].hide();
    }
  }

  _initInterface() {
    return new PlayerUIInterface(this);
  }

  _setupRoot() {
    this.root.setAttribute('id', 'root-ui');
    this.root.classList.add('ui-container');

    this.safeZone = document.createElement('div');
    this.safeZone.classList.add('ui-container__safe_zone');
    this.root.appendChild(this.safeZone);
  }

  _createInterfaceContainer() {
    const interfaceContainer = document.createElement('div');
    interfaceContainer.setAttribute('id', 'player-interface-container');
    this.safeZone.append(interfaceContainer);
    return interfaceContainer;
  }

  get controllersIdPrefix() {
    return 'player_ui';
  }

  get controllerTypes() {
    return eControllerTypes;
  }

  set scaleData(data) {
    this._scaleData = data;
    const uiScale = Math.min(this._scaleData.innerWidth / window.OPWrapperService.ScaleManager.safeZone.width, this._scaleData.innerHeight / window.OPWrapperService.ScaleManager.safeZone.height);

    for (const key in this.controllers) {
      const controller = this.controllers[key];
      controller.scaleData = { scaleData: this._scaleData, rootScale: uiScale };
    }

    const width = 100 * (1 / uiScale);
    const left = -(width - 100) / 2;
    const height = 100 * (1 / uiScale);
    const top = -(height - 100) / 2;

    const safeWidth = this._scaleData.stageX > 0 ? (this._scaleData.stageX * 2) / this._scaleData.scale : 0;
    const safeHeight = this._scaleData.stageY < 0 ? (this._scaleData.stageY * 2) / this._scaleData.scale : 0;
    const safeLeftOffset = this._scaleData.stageX > 0 ? this._scaleData.stageX / this._scaleData.scale : 0;
    const safeTopOffset = (this._scaleData.stageY < 0 ? this._scaleData.stageY / this._scaleData.scale : 0) - this._scaleData.bottomOffset;

    let marginX = window.OPWrapperService.ScaleManager.safeZone.left;
    let marginY = window.OPWrapperService.ScaleManager.safeZone.top;

    if (this._scaleData.stageX < 0) {
      marginX += this._scaleData.stageX / this._scaleData.scale;
    }
    if (this._scaleData.stageY > 0) {
      marginY += this._scaleData.stageY / this._scaleData.scale;
    }

    this.safeZone.style.marginRight = `${marginX}px`;
    this.safeZone.style.marginLeft = `${marginX}px`;

    this.safeZone.style.marginTop = `${marginY}px`;
    this.safeZone.style.marginBottom = `${marginY}px`;
    this.safeZone.style.height = `calc(100% - ${marginY * 2}px)`;

    Object.assign(this.root.style, {
      transform: `scale(${uiScale}) translateZ(0)`,
      width: `calc(${width}% - ${safeWidth}px)`,
      height: `calc(${height}% - ${safeHeight}px)`,
      left: `calc(${left}% + ${safeLeftOffset}px)`,
      top: `calc(${top}% + ${safeTopOffset}px)`,
    });
  }
}
