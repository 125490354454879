import BaseSystemController from '../system/baseSystemController';
import { TournamentNotification } from './system';
import eControllerTypes from '../system/eControllerTypes';
import eNotificationTypes from './eNotificationTypes';

export default class ControllerNotificationsManager extends BaseSystemController {
  constructor({ container, ...props }) {
    super({ type: eControllerTypes.ECT_NOTIFICATIONS, ...props });
    this._defaultSizes = {
      H: {
        width: 1920,
        height: 1080,
      },
      V: {
        width: 918,
        height: 1920
      }
    };

    this._eElementsTypes = {
      EET_CONTAINER_ROOT: 'container_root',
      EET_CONTAINER_NOTIFICATIONS: 'container_notifications',
    }
    this._notificationClasses = {
      [eNotificationTypes.ENT_TOURNAMENT_STATUS_CHANGED]: TournamentNotification,
    };

    this._activeNotification = null;
    this.init(container);
  }

  _afterInit() {
    super._afterInit();
    this._createIdGenerator();
    this.root = this._interactiveElements[this._eElementsTypes.EET_CONTAINER_ROOT];
  }

  showNotification(type, data) {
    if (!this._notificationClasses.hasOwnProperty(type)) return console.error(`Unhandled notification type: '${type}'`);
    this._execute(type, data);
  }

  _execute(type, data) {
    this._removeActiveNotification();

    this._activeNotification = new this._notificationClasses[type]({ ...data, _id: this._idGenerator.next().value });
    this._activeNotification.init(this.interactiveElements[this._eElementsTypes.EET_CONTAINER_NOTIFICATIONS]);
    this._activeNotification.on(this._activeNotification.COMPLETE_EVENT, (controller) => {
      if (this._activeNotification === controller) this._activeNotification = null;
      controller.remove();
    })
  }

  _removeActiveNotification() {
    if (!this._activeNotification) return;

    this._activeNotification.kill();
    this._activeNotification = null;
  }

  _createIdGenerator() {
    this._idGenerator = (function* () {
      let id = 0;
      while (true) {
        yield id;
        id++;
      }
    })();
  }

  set scaleData({ scaleData, rootScale }) {
    this._scaleData = scaleData;
    const orientation = this._scaleData.innerWidth <= this._scaleData.innerHeight ? 'V' : 'H';
    const uiScale = orientation === 'H' ? Math.min(this._scaleData.innerWidth / this._defaultSizes[orientation].width, this._scaleData.innerHeight / this._defaultSizes[orientation].height) : this._scaleData.innerWidth / this._defaultSizes[orientation].width;

    for (const key in this.controllers) {
      const controller = this.controllers[key];
      controller.scaleData = { scaleData: this._scaleData, rootScale: uiScale };
    }

    const width = 100 * (1 / uiScale);
    const left = -(width - 100) / 2;
    const height = 100 * (1 / uiScale);
    const top = -(height - 100) / 2;

    let safeWidth = 0;
    let safeHeight = 0;
    let safeLeftOffset = 0;
    let safeTopOffset = 0;

    if (orientation === 'H') {
      safeWidth = (this._scaleData.innerWidth - (this._defaultSizes[orientation].width * uiScale)) / uiScale;
      safeHeight = (this._scaleData.innerHeight - (this._defaultSizes[orientation].height * uiScale)) / uiScale;
      safeLeftOffset = safeWidth / 2;
      safeTopOffset = safeHeight / 2;
    }

    Object.assign(this.root.style, {
      transform: `scale(${uiScale}) translateZ(0)`,
      width: `calc(${width}% - ${safeWidth}px)`,
      height: `calc(${height}% - ${safeHeight}px)`,
      left: `calc(${left}% + ${safeLeftOffset}px)`,
      top: `calc(${top}% + ${safeTopOffset}px)`,
    });
  }

  _getMarkup() {
    return `<div id=${this.interactiveElementsIds[this._eElementsTypes.EET_CONTAINER_ROOT]} class="OPWrapper_service__notifications system-ui-container__notifications">
          <div id=${this.interactiveElementsIds[this._eElementsTypes.EET_CONTAINER_NOTIFICATIONS]} class="OPWrapper_service__notifications__wrapper">
          </div>
        </div>`
  }
}
