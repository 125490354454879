import FlashLib from 'flashlib';
import * as PIXI from 'pixi.js';
import WebFont from 'webfontloader';

import Assets from '../assets/flashlib/exported/FlashLibAssets';
import Meta from '../assets/flashlib/exported/FlashLib';
import ControllerUI from './ui/ControllerUI';
import { requestConfig } from './api/ControllerApi';

import LocalizeTextField from './i18n/LocalizeTextField';
import EMAdapter from './platforms/everyMatrix/adapter';
import Config from './Config';

export default class Game {
  constructor() {
    this.app = null;
    this.compiled = false;

    this.configLoaded = false;
    this.fontsLoaded = false;
    this.assetsLoaded = false;
    this.soundsLoaded = false;
    this.animationsLoaded = false;
    this.gameInitilized = false;

    this.config = {
      width: 1165,
      height: 1031
    };

    PIXI.settings.FAIL_IF_MAJOR_PERFORMANCE_CAVEAT = false;

    EMAdapter.init();
    this.start();

    FlashLib.TextField = LocalizeTextField;
  }

  start() {
    this.applicationOptions = {
      // antialias: true,
      // backgroundColor: 0x6fc1f6,
      // legacy: true,
      transparent: true,
      width: document.documentElement.clientWidth,
      height: document.documentElement.clientHeight,
      resolution: this.isRetinaDisplay() ? 1 : 1,
      autoResize: true
    };
    this.app = new PIXI.Application(this.applicationOptions);
    this.app.view.id = 'gameCanvas';
    this.container = document.getElementById('container');
    this.container.appendChild(this.app.view);

    this.gameSize = {
        width: 2340,
        height: 1080
    };

    this.scaleData = {
      type: 'css',
      app: this.app,
      safeWidth: 960*4,
      safeHeight: 536*4,
      fullSize: true,
      gameSize: this.gameSize
    };

    window.OPWrapperService.eventManager.add(
      window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
      this.onResize,
      this
    );

    this.loadAssets();
    this.loadFonts();
    this.loadAnimations();
    requestConfig()
      .then(() => {
        console.log('Config loaded');
        this.configLoaded = true;
        this.tryInitGame();
      });
    PIXI.Loader.shared.load();
  }

  loadAssets() {
    PIXI.Loader.shared.add('FlashLib', Meta, 'json');
    PIXI.Loader.shared.add('FlashLibAssets', Assets, 'json');
    PIXI.Loader.shared.onComplete.add(() => {
      console.log('Assets loaded');
      this.assetsLoaded = true;
      this.tryInitGame();
    }, this);
    PIXI.Loader.shared.onError.add(this.onLoadingError.bind(this));
    PIXI.Loader.shared.onProgress.add((data) => {
      OPPreloader.setProgress(data.progress);
      EMAdapter.loadProgress(data.progress);
    });
  }

  loadFonts() {
    WebFont.load({
      custom: {
        families: ['lobster', 'SegoeUI'],
      },
      fontinactive: console.error,
      active: () => {
        console.log('Fonts loaded');
        this.fontsLoaded = true;
        this.tryInitGame();
      },
      inactive: this.onLoadingError.bind(this),
      timeout: 60000
    });
  }

  onLoadingError(err) {
    console.error(err);
    window.OPWrapperService.showError(window.OPWrapperService.errors.ASSETS_ERROR.CODE);
    PIXI.Loader.shared.reset();
  }
  loadAnimations() {
    PIXI.Loader.shared.add('coin_animation', '/animations/coin/x2coin.json', 'json');
    PIXI.Loader.shared.onComplete.add(() => {
      console.log('Animations loaded');
      this.animationsLoaded = true;
      this.tryInitGame();
    }, this);
  }

  tryInitGame() {
    if (this._readyForInit()) {
      this.initGame();
    }
  }

  get ready() {
    return this._readyForInit() && this.gameInitilized;
  }

  _readyForInit() {
    return (
      this.fontsLoaded &&
      this.assetsLoaded &&
      this.animationsLoaded &&
      this.configLoaded
      // this.soundsLoaded &&
    )
  }

  initGame() {
    EMAdapter.loadCompleted();
    const main = FlashLib.createItemFromLibrary('main', 'FlashLib');
    main.visible = false;
    this.controllerUI = new ControllerUI(main, this.container);
    EMAdapter.controllerUI = this.controllerUI;
    this.app.stage.addChild(main);
    this.mainContainer = main;

    this.gameInitilized = true;
    window.OPPreloader.hide();
    window.OPWrapperService.freeBetsController.show();
    window.OPWrapperService.Fullscreen.showButtonIfAndroid();
    window.OPWrapperService.ScaleManager.init(this.scaleData, Config)
  }

  show(id, amount, currency) {
    this.controllerUI.show(id, amount, currency);
  }

  onResize(data) {
    if (!this.mainContainer) return;
    this.mainContainer.pivot.set(this.mainContainer.width / 2, this.mainContainer.height / 2);
    this.mainContainer.x = data.gameWidth / 2;
    this.mainContainer.y = data.gameHeight / 2;
  };

  //todo: move to another place
  isRetinaDisplay() {
    let result = false;
    if (window.matchMedia) {
      let mq = window.matchMedia('only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 2.6/2), only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen  and (min-device-pixel-ratio: 1.3), only screen and (min-resolution: 1.3dppx)');
      result = (mq && mq.matches || (window.devicePixelRatio > 1));
    }
    return result;
  }
}
