import FlashLib from "flashlib";

export default class LocalizeTextField extends FlashLib.TextField {
  constructor(data, displayData) {
    super(data, displayData);
    this.LocalizationManager = window.OPWrapperService.localizations;
    this.LocalizationManager.addTextField(this);
  }

  setTextParams(...params) {
    this.text = this.LocalizationManager.getLocalizedText(this.name, params);
  }

  destroy(options) {
    this.LocalizationManager.removeTextField(this);
    super.destroy(options);
  }
}
