import BaseManagerHttp from './base/baseManagerHttp';

export default class ManagerHttp extends BaseManagerHttp {

  async getTournamentsAuthorizationToken() {
    const mode = this.mode ? this.mode.concat('.') : '';
    return await this.postRequest(`https://tournaments.${mode}onlyplay.net/control/ws/key`, {
      gid: this.gid,
      sid: this.sid,
    });
  }
}
