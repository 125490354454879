import ControllerTournamentsHeader from '../tournaments/ControllerHeader';
import eControllerTypes from './eControllerTypes';

export default class ControllerStartScreenHeader extends ControllerTournamentsHeader {

  get controllerType() {
    this._type = eControllerTypes.ETSCT_HEADER
    return super.controllerType;
  }

  get elementsIdPrefix() {
    return 'tournament_start_screen';
  }

  get showArchiveButton() {
    return false;
  }
}
