import ControllerBanner from '../tournaments/ControllerBanner';
import eControllerTypes from './eControllerTypes';

export default class ControllerStartScreenBanner extends ControllerBanner {

  get controllerType() {
    this._type = eControllerTypes.ETSCT_BANNER
    return super.controllerType;
  }

  get elementsIdPrefix() {
    return 'tournament_start_screen';
  }
}
