import EventEmitter from '../eventEmitter';
import { format } from '../../utils/formatCurrency';

export default class BaseController extends EventEmitter {
  constructor({ type, overlayHandlers }) {
    super();
    this._type = type;
    this._overlayHandlers = overlayHandlers;
    this._isOverlayToggler = false;
    this._isSpinBlocker = false;
    this._mountPosition = 'afterbegin';
    this._defaultCurrencyDecimal = 2;
    this._eElementsTypes = null;
    this._eButtonsTypes = null;
    this._eButtonsActions = null;
    this._eEventTypes = null;
    this._interactiveElementsIds = null;
    this._localizations = null;
    this._localizationKeysValues = null;
    this._localizationValues = null;
    this._adaptiveElements = null;
    this._updateLocalizations = this._updateLocalizations.bind(this);
    window.OPWrapperService.localizations.addLocalizationChangedCallback(this._updateLocalizations);
  }

  init(container) {
    this._container = container;
    container.insertAdjacentHTML(this._mountPosition, this._getMarkup());
    this._mounted = true;

    this._saveInteractiveElements();
    this._setAdaptationListeners();
    this.addActions();
    this._afterInit();
  }

  set(type, value) {
    if (!this._interactiveElements[type]) throw new Error(`Unhandled element type: ${type}`);
    this._interactiveElements[type].innerHTML = String(value);
  }

  addActions() {
    if (!this._eButtonsTypes) return;

    for (let key in this._eButtonsTypes) {
      const type = this._eButtonsTypes[key];
      const button = this._interactiveElements[type];
      button.addEventListener('click', (e) => {
        if (this._eButtonsActions && this._eButtonsActions.hasOwnProperty(type)) {
          this._eButtonsActions[type](e);
        }
        this.emit(this.getEventName(type));
      }, true);
    }
  }

  getEventName(buttonType) {
    return `${this.controllerType}__${buttonType}:click`
  }

  block(types) {
    const buttonTypes = this._getButtonTypesArray(types);
    buttonTypes.forEach(type => {
      const button = this._interactiveElements[type];
      button.setAttribute('disabled', '');
    });
  }

  unblock(types) {
    const buttonTypes = this._getButtonTypesArray(types);

    buttonTypes.forEach(type => {
      const button = this._interactiveElements[type];
      button.removeAttribute('disabled');
    });
  }

  isBlocked(type) {
    const button = this._interactiveElements[type];
    return button.getAttribute('disabled') !== null;
  }

  show() {
    if (this._isOverlayToggler && this._overlayHandlers) {
      this._overlayHandlers.show(this.controllerType);
    }
    if (this._isSpinBlocker) window.OPWrapperService.model.blockSpin(this.controllerType);

    this._container.classList.remove('hidden');
    this.onShow();
  }

  hide() {
    if (this._isOverlayToggler && this._overlayHandlers) {
      this._overlayHandlers.hide(this.controllerType);
    }
    if (this._isSpinBlocker) window.OPWrapperService.model.unblockSpin(this.controllerType);

    this._container.classList.add('hidden');
    this.cleanup();
  }

  beforeRemove() {
    this.cleanup();
    window.OPWrapperService.localizations.removeLocalizationChangedCallback(this._updateLocalizations);
    this._mounted = false;
  }

  cleanup() {}

  onShow() {}

  _afterInit() {}

  _setAdaptationListeners() {
    if (!this._adaptiveElements) return;
    const resizeObserver = new ResizeObserver((mutations) => {
      mutations.forEach(mutation => {
          this._adaptFontSize(mutation.target);
      })
    });
    const mutationObserver = new MutationObserver((mutations) => {
      mutations.forEach(mutation => {
        if (mutation.type === 'childList' || mutation.type === 'characterData') this._adaptFontSize(mutation.target)
      })
    });

    for (const type of this._adaptiveElements) {
      resizeObserver.observe(this.interactiveElements[type]);
      mutationObserver.observe(this.interactiveElements[type], { childList: true, characterData: true });
    }
  }

  _updateLocalizations() {
    if (!this._localizations || !this._mounted) return;
    for (let type in this._localizations) {
      let key = this._localizations[type];
      if (this._localizationKeysValues && this._localizationKeysValues[type]) {
        key = this._substituteLocalizationParams(key, this._localizationKeysValues[type]);
      }

      let text = this._getLocalization(key);
      if (this._localizationValues && this._localizationValues[type]) {
        text = this._substituteLocalizationParams(text, this._localizationValues[type]);
      }

      this.set(type, text);
    }
  }

  _substituteLocalizationParams(string, values) {
    let result = string;

    for (let replaceKey in values) {
      result = result.replace(new RegExp(replaceKey.replace('$', '\\$'), 'gi'), values[replaceKey]);
    }

    return result;
  }

  _getLocalization(key) {
    return window.OPWrapperService.localizations.getLocalizedText(key);
  }

  _getButtonTypesArray(types) {
    if (!Array.isArray(types)) {
      types = types ? [types] : [];
    }

    if (!types.length) {
      types = Object.values(this._eButtonsTypes);
    }

    return types;
  }

  _getMarkup() {
    throw new Error(`You must override 'getMarkup' method inside derived class`);
  }

  get elementsIdPrefix() {
    throw new Error(`You must override 'get elementsIdPrefix' method inside derived class`);
  }

  _saveInteractiveElements() {
    this._interactiveElements = {};
    for (let type in this.interactiveElementsIds) {
      const id = this.interactiveElementsIds[type];
      const element = document.getElementById(id);

      if (!element) throw new Error(`Can't find element by given id: '${id}'`);
      this._interactiveElements[type] = element;
    }
  }

  _adaptFontSize(el) {
    if (getComputedStyle(el).fontSize === '') return;
    // css overflow property must be specified on an element;
    if (!this._prefferedFontSizes) {
      this._prefferedFontSizes = new Map();
    }
    if (!this._prefferedFontSizes.has(el)) {
      this._prefferedFontSizes.set(el, parseFloat(getComputedStyle(el).fontSize));
    }

    while (el.offsetWidth === el.scrollWidth) {
      const fontSize = parseFloat(getComputedStyle(el).fontSize);
      if (fontSize === this._prefferedFontSizes.get(el)) break;
      el.style.fontSize = (fontSize + 1) + 'px';
    }
    while (el.offsetWidth < el.scrollWidth) {
      const fontSize = parseFloat(getComputedStyle(el).fontSize);
      el.style.fontSize = (fontSize - 1) + 'px';
    }
  }

  _moneyFormat(value) {
    return `${format(value, window.OPWrapperService.currencyInfo.decimals)} ${window.OPWrapperService.currencyInfo.currency.toUpperCase()}`
  }

  get interactiveElementsIds() {
    if (!this._interactiveElementsIds) {
      this._interactiveElementsIds = {};
      for (let key in this.elementsTypes) {
        const type = this.elementsTypes[key];
        this._interactiveElementsIds[type] = `${this.elementsIdPrefix}__${this.controllerType}__${type}`;
      }
    }
    return this._interactiveElementsIds;
  }

  get elementsTypes() {
    return this._eElementsTypes;
  }

  get interactiveElements() {
    return this._interactiveElements;
  }

  get buttonsTypes() {
    return this._eButtonsTypes;
  }

  get events() {
    if (!this._eEventTypes) return;
    return { [this.controllerType]: this._eEventTypes }
  }

  get controllerType() {
    if (!this._type) {
      throw new Error(`You must specify controller type for '${this.constructor.name}'`);
    }
    return this._type;
  }

  get isHidden() {
    return this._container.classList.contains('hidden');
  }

  set scaleData(data) {

  }
}
