import BaseTournamentController from './tournaments/baseTournamentController';
import eControllerTypes from './eControllerTypes';
import { OP_WRAPPER_LOCALIZATION_PREFIX } from '../../../utils/constants';

export default class ControllerModalTournamentEnd extends BaseTournamentController {
  constructor({ container, ...props }) {
    super({ type: eControllerTypes.ECT_MODAL_TOURNAMENT_END, ...props });
    this._defaultSizes = {
      H: {
        width: 1920,
        height: 1080,
      },
      V: {
        width: 918,
        height: 1920
      }
    };

    this._eButtonsTypes = {
      EBT_CLOSE: 'close_button',
      EBT_REVIEW: 'review_result_button',
    };
    this._eButtonsActions = {
      [this._eButtonsTypes.EBT_CLOSE]: () => this.hide(),
      [this._eButtonsTypes.EBT_REVIEW]: () => {
        this.hide();
        if (this._tournamentInfo) window.OPWrapperService.SystemUI.showTournamentResults(this._tournamentInfo.uniqueId);
      },
    };
    this._eEventTypes = {
      CLOSE_CLICK: this.getEventName(this._eButtonsTypes.EBT_CLOSE),
      REVIEW_CLICK: this.getEventName(this._eButtonsTypes.EBT_REVIEW),
    };
    this._eElementsTypes = {
      EET_CLOSE_BUTTON: this._eButtonsTypes.EBT_CLOSE,
      EET_REVIEW_BUTTON: this._eButtonsTypes.EBT_REVIEW,
      EET_REVIEW_BUTTON_LABEL: `${this._eButtonsTypes.EBT_REVIEW}_label`,
      EET_SCALE_ROOT: 'scale_root',
      EET_TOURNAMENT_TITLE: 'tournament_title',
      EET_TITLE: 'title',
      EET_DESCRIPTION: 'description',
    };
    this._localizations = {
      [this._eElementsTypes.EET_TITLE]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.tournaments.modalTournamentEnd.title`,
      [this._eElementsTypes.EET_DESCRIPTION]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.tournaments.modalTournamentEnd.description`,
      [this._eElementsTypes.EET_REVIEW_BUTTON_LABEL]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.tournaments.modalTournamentEnd.button_review`,
    };

    this.init(container);
    this.root = this.interactiveElements[this._eElementsTypes.EET_SCALE_ROOT];
    this.hide();
  }

  show(tournamentId) {
    this._update(tournamentId);
    super.show();
  }

  _update(tournamentId) {
    if (!this._data) return;
    this._tournamentInfo = this._data.find(tournamentDetails => tournamentDetails.uniqueId === tournamentId);
    if (!this._tournamentInfo) return;

    this._localizationValues = {
      [this._eElementsTypes.EET_DESCRIPTION]: { $tournamentTitle: `<b>${this._tournamentInfo.title}</b>` },
    }

    const descriptionText = this._getLocalization(this._localizations[this._eElementsTypes.EET_DESCRIPTION]).replace('$tournamentTitle', `<b>${this._tournamentInfo.title}</b>`);
    this.set(this._eElementsTypes.EET_DESCRIPTION, descriptionText);
    this.set(this._eElementsTypes.EET_TOURNAMENT_TITLE, this._tournamentInfo.title);
  }

  get dataSelector() {
    return (data) => data.archived;
  }

  set scaleData({ scaleData, rootScale }) {
    this._scaleData = scaleData;
    const orientation = this._scaleData.innerWidth <= this._scaleData.innerHeight ? 'V' : 'H';
    const uiScale = Math.min(this._scaleData.innerWidth / this._defaultSizes[orientation].width, this._scaleData.innerHeight / this._defaultSizes[orientation].height);

    Object.assign(this.root.style, {
      transform: `scale(${uiScale}) translateZ(0)`,
    });
  }

  _getMarkup() {
    return `<div class="system-ui-container__modal_wrapper">
              <div id=${this.interactiveElementsIds[this._eElementsTypes.EET_SCALE_ROOT]} class="system-ui-container__modal_container system-ui-container__tournaments__modal__tournament_end">
                <div class="system-ui-container__tournaments__modal__tournament_end__header">
                  <div>
                    <span id=${this.interactiveElementsIds[this._eElementsTypes.EET_TOURNAMENT_TITLE]}>Golden Gates</span>
                    <span id=${this.interactiveElementsIds[this._eElementsTypes.EET_TITLE]}>${this._getLocalization(this._localizations[this._eElementsTypes.EET_TITLE])}</span>
                  </div>
            
                  <button id=${this.interactiveElementsIds[this._eElementsTypes.EET_CLOSE_BUTTON]} class="system-ui-container__close_button">
                    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M20.9989 51.3331H34.9989C46.6655 51.3331 51.3322 46.6664 51.3322 34.9997V20.9997C51.3322 9.33305 46.6655 4.66638 34.9989 4.66638H20.9989C9.33219 4.66638 4.66553 9.33305 4.66553 20.9997V34.9997C4.66553 46.6664 9.33219 51.3331 20.9989 51.3331Z"
                          fill="#17142A" stroke="#FFFFFF" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M21.3965 34.6032L34.6032 21.3965" stroke="white" stroke-width="2.5" stroke-linecap="round"
                            stroke-linejoin="round" />
                      <path d="M34.6032 34.6032L21.3965 21.3965" stroke="white" stroke-width="2.5" stroke-linecap="round"
                            stroke-linejoin="round" />
                    </svg>
                  </button>
                </div>
            
                <div class="system-ui-container__tournaments__modal__tournament_end__body">
                  <div class="system-ui-container__tournaments__modal__tournament_end__body_content">
                    <p id=${this.interactiveElementsIds[this._eElementsTypes.EET_DESCRIPTION]}>${this._getLocalization(this._localizations[this._eElementsTypes.EET_DESCRIPTION])}</p>
                  </div>
                  <button id=${this.interactiveElementsIds[this._eElementsTypes.EET_REVIEW_BUTTON]} class="secondary">
                    <span id=${this.interactiveElementsIds[this._eElementsTypes.EET_REVIEW_BUTTON_LABEL]}>${this._getLocalization(this._localizations[this._eElementsTypes.EET_REVIEW_BUTTON_LABEL])}</span>
                  </button>
                </div>
              </div>
            </div>`
  }
}
